import React from 'react';
import Header from '../base/header';
import Yobologo from '../base/yobologo';
import YCSCarousel from '../base/carousel';
import Footer from '../base/footer';

class Home extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<div>
				<Header />
				<div className="container-fluid" id="top-container-fluid">
					<div className="row">
						<div className="col-sm-1 col-md-1 col-lg-1">

						</div>
						<div className="col-sm-10 col-md-10 col-lg-10" id="logo-container">
							<Yobologo className="yoboLogoStyleHome" />
						</div>
						<div className="col-sm-1 col-md-1 col-lg-1">

						</div>
					</div>
					<div className="row">
						<div className="col-sm-1"></div>
						<div className="col-sm-10">

							<h2 className="bootstrap-overrides" id="service_area">Serving San Francisco, CA and the Bay area</h2>
							<br />

							<h2 className="bootstrap-overrides homeH2">Welcome to Yobo Computer Systems!</h2>


							<p>Is your computer running slow or have any other issues? Does it need software or hardware updates or just a tune up? Or probably, do you need a qualified
							CompTIA A+ Certified technician to help you with computer technical support, repair and maintenance?
							</p>

							<p>
								If your answer is yes to any of the questions above then you came to the right place! In Yobo Computer Systems we provide highly qualified IT
								services right at your home or small business while we keep everything in a simple, professional and friendly way! Feel free to give us a call
								today to schedule an appointment!
							</p>

							<br />

							<h2 className="bootstrap-overrides homeH2">Why should I call Yobo Computer Systems for my IT issues?</h2>
							<ul id="why-us">
								<li>Professional and CompTIA A+ Certified Technician with 5+ years of experience</li>
								<li>We enjoy working and resolving tech challenges while giving you affordable estimates</li>
								<li>Our customers consider us as honest, friendly and professional in everything we do</li>
								<li>We truly value your business, time and trust you deposit in us!</li>
							</ul>


						</div>
						<div className="col-sm-1"></div>
					</div>
					<br></br>
					<div className="row">
						<div className="col-sm-1 col-md-1 col-lg-2 col-xl-3">

						</div>
						<div className="col-sm-10 col-md-10 col-lg-8 col-xl-6">
							<YCSCarousel />
							<br />
							<br />
							<br />
							<h4 className="bootstrap-overrides" id="operationHours">Our operation hours are Monday-Friday from 8:00 AM to 5:00 PM and from 8:00 AM to 12:00 PM on Saturdays</h4>
						</div>
						<div className="col-sm-1 col-md-1 col-lg-2 col-xl-3">
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}
export default Home;
