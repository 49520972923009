import React from 'react';
import Header from '../base/header';
import Yobologo from '../base/yobologo';
import Footer from '../base/footer';

class Services extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<div>
				<Header />
				<div className="container-fluid" id="top-container-fluid">
					<div className="row">
						<div className="col-sm-1">

						</div>
						<div className="col-sm-10" id="logo-container">
							<Yobologo className="yoboLogoStyleHome" />
						</div>
						<div className="col-sm-1">

						</div>
					</div>
					<div className="row">
						<div className="col-sm-1 col-md-1 col-lg-1"></div>
						<div className="col-12 col-sm-10 col-md-10 col-lg-10">
							<p>
								With Yobo Computer Systems you can always expect friendliness and professionalism in all of our services we offer.
							</p>

							<h2 className="bootstrap-overrides" id="contentH2">We offer the following services: </h2>
							<br></br>

							<div className="service_container">
								<div>
									<div className="services_images s_i_left" id="services_images_repair"></div>
								</div>
								<div className="services_r services_description s_d_right" id="services_r_repair">
									<div className="responsive_background">
										<h4 className="bootstrap-overrides" id="contentH4">Computer repair, maintenance and upgrades</h4>
										<p>
											Whether you have a minor software related issue or a major hardware problem Yobo Computer Systems got you covered!

											We troubleshoot hardware to provide you with the right diagnosis and bring your PC back to normal operation. We also do new hardware
											installation and upgrade to improve your PC performance and stability!
								</p>
									</div>
								</div>
							</div>

							<div className="service_container">
								<div>

									<div className="services_images s_i_right" id="services_images_support"></div>
								</div>
								<div className="services_r services_description s_d_left" id="services_r_support">
									<div className="responsive_background">
										<h4 className="bootstrap-overrides" id="contentH4">Malware removal, Operating System installation, optimization and setup</h4>
										<p>
											Did you know that application crashes, low performance, random errors and frozen windows could be a sign of malware infection?

											In Yobo Computer Systems we can scan your computer for malware and safely remove it, enhancing your privacy and improving the overall
											performance of your system.

											We also can help you with a new Windows installation, proper set up and install any software you need.
									</p>
									</div>
								</div>
							</div>

							<div className="service_container">
								<div>
									<div className="services_images s_i_left" id="services_images_consulting"></div>
								</div>
								<div className="services_r services_description s_d_right" id="services_r_consulting">
									<div className="responsive_background">
										<h4 className="bootstrap-overrides" id="contentH4">IT Consulting and technical support</h4>
										<p>
											Do you need a new modem, router, printer or any device installed and properly set up?

											Having a technical question about your PC performance or functionality? Or maybe do you just need help getting the right software
											or hardware for your computer?

											Yobo Computer Systems can offer you with affordable and professional technical support and consulting services. With a current CompTIA A+
											certification and more than 5 years of experience we can provide you with the most innovative IT solutions for your home and small business.
										 </p>
									</div>
								</div>
							</div>

							<div className="service_container" id="last_service">
								<div>
									<div className="services_images s_i_right" id="services_images_network"></div>
								</div>
								<div className="services_r services_description s_d_left" id="services_r_network">
									<div className="responsive_background">
										<h4 className="bootstrap-overrides" id="contentH4">Home and small business network setup</h4>
										<p>
											We all have experienced an unstable Internet connection and it is frustrating when we are working online and websites don't load
											or while watching our favorite movies we get unexpected interruptions.

											Yobo Computer Systems will test your current equipment for issues and set it up properly. We can check for dead spots and help you improving Wi-Fi signal
											in your home or small office.

											We are familiar with the current modem and router brands and settings and we can also help you setting your network up so you access the Internet safely.
									</p>
									</div>
								</div>
							</div>
							<br></br>
							<br></br>
							<div className="service_container">
								<h4 className="bootstrap-overrides" id="contentH4">Did we forget to mention something? Please contact Yobo Computer Systems with any question you have!</h4>
								<br />
								<br />
								<br />
								<h4 className="bootstrap-overrides" id="operationHours">Our operation hours are Monday-Friday from 8:00 AM to 5:00 PM and from 8:00 AM to 12:00 PM on Saturdays</h4>
							</div>
						</div>
						<div className="col-sm-1 col-md-1 col-lg-1"></div>
					</div>
				</div>
				<Footer />

			</div>
		)
	}
}
export default Services;
