import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import img1 from '../../media/laptopw10.jpg';
import img2 from '../../media/peripherals.jpg';
import img3 from '../../media/hardware.jpg';

class YCSCarousel extends Component {
	render() {
		return (
				<Carousel className="carousel-custom-style" showThumbs={false} showStatus={false} autoPlay interval={4000} infiniteLoop>
					<div>
					<img src={img1} />
					<p className="legend">Operating System installation, setup and updates</p>
					</div>

					<div>
					<img src={img2} />
					<p className="legend">Hardware installation and setup</p>
					</div>

					<div>
					<img src={img3} />
					<p className="legend">Computer hardware and components upgrades for optimization</p>
					</div>
				</Carousel>
		       );
	}
};

export default YCSCarousel;
