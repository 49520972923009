import React from 'react';
import { Link } from 'react-router-dom';

class Header extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};

		this.handleScroll = this.handleScroll.bind(this);
		this.opacityControl = this.opacityControl.bind(this);

	}

	

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		this.opacityControl(document.getElementById("this-topnavbar"));
	}

	handleScroll() {
		var hB = document.getElementById("this-topnavbar");
		var cB = document.getElementById("toggle");

	
		if(window.matchMedia("(max-width: 575px)").matches) {
			hB.style.transition = "0.4s all ease"
			hB.style.opacity = 1-((window.pageYOffset/100)/3.5);
			

		if(hB.style.opacity<=0) {
			cB.checked = false;
		}
		}
		else {
			hB.style.opacity = "1"
			this.opacityControl(hB);
		}
		
	}

	opacityControl(hB) {
		if (window.pageYOffset >= 40) {
			hB.classList.add("nav-down");
			hB.classList.remove("nav-up");
		}
		else {
			hB.classList.add("nav-up");
			hB.classList.remove("nav-down");
		}
	}

	render() {
		return (
			<div>
				<div className="container-fluid" id="bootstrap-overrides">
					<div className="row">
						<div className="nav col-sm-12 col-md-12">
							<label htmlFor="toggle" className="bootstrap-overrides">&#9776;</label>
							<input type="checkbox" id="toggle"/>
							<div className="menu">
							<ul className="topnavbar nav-up" id="this-topnavbar">
								<li><Link to="/">Home</Link></li>
								<li><Link to="/services">Services</Link></li>
								<li><Link to="/about">About</Link></li>
								<li><Link to="/contact">Contact</Link></li>
							</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}
}
/*<li><Link to="/schedapt">Schedule an appointment</Link></li>*/
export default Header;
