import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
//import App from './App';
import {
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom'
import Home from './components/home/home';
import Services from './components/services/services';
import About from './components/about/about';
import Contact from './components/contact/contact';
import * as serviceWorker from './serviceWorker';

//ReactDOM.render(<App />, document.getElementById('root'));

class IndexMain extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render() {
		return (
			<Router>
				<div>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route path="/services" component={Services} />
						<Route path="/about" component={About} />
						<Route path="/contact" component={Contact} />
						<Route component={Home} />
					</Switch>
				</div>
			</Router>
		)
	}
}
/*<Route path="/schedapt" component={Schedapt}/>*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
ReactDOM.render(<IndexMain />, document.getElementById('root'));

serviceWorker.unregister();
