import React from 'react';
import Header from '../base/header';
import Yobologo from '../base/yobologo';
import Footer from '../base/footer';

class About extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<div>
				<Header />
				<div className="container-fluid" id="top-container-fluid">
					<div className="row">
						<div className="col-sm-1">

						</div>
						<div className="col-sm-10" id="logo-container">
							<Yobologo className="yoboLogoStyleHome" />
						</div>
						<div className="col-sm-1">

						</div>
					</div>
					<div className="row">
						<div className="col-sm-1 col-lg-2"></div>
						<div className="col-sm-10 col-lg-8">

							<div id="media_container">
								<div id="about_profile_image"><div className="about_aplus_logo" id="aplus_nested"></div></div>
							</div>

							<div className="about_description">
								<p>
									Hi! My name is Jose Roberto Orellana Ramos, also known as Yobo by my family and friends.
							</p>

								<p>
									I became fascinated with computers since I was a teenager and got my first one with Windows 2000 (back in 2003). I did not have
									an Internet connection at that time but that did not stop me from learning because I love to learn things by myself and that was the
									perfect opportunity. From setting up, removing malware, reinstalling Operating Systems and adding new devices to my own computer I got some
									experience and felt confident enough to help school teachers, friends and neighbors with their computer needs.
							</p>

								<p>
									I studied four years in college majoring Systems Engineering which broaden my experience in the field. While studying I also decided to take
									some computer programming and web development classes and worked on some small projects by myself. This was a great experience for me to understand
									how applications interact with Operating Systems and to troubleshoot issues.
							</p>

								<div className="about_aplus_logo" id="aplus_full"></div>

								<p>
									While studying I was working full time in a call center as a bilingual (English and Spanish) technical support representative, that taught me
									a great lesson: besides getting the right training from the company I learned a lot from our customers, understand their concerns and what they
									need and work together towards a solution while showing empathy and professionalism in every single interaction.
							</p>

								<p>
									It is really important to understand the fact that IT is constantly evolving, there are always new concepts, technologies, platforms and devices
									to learn about and I was excited to study and get my CompTIA A+ Certification in January 2019. This is a qualifying credential for technical
									support and IT operational roles.
							</p>

								<p>
									So, having all the tools, knowledge, experience and all my communication skills necessary to work on the IT field motivated me to start my own
									business. You can count with Yobo Computer Systems with everything related to IT and always expect friendliness, honesty and great quality in every job we
									do!
							</p>

							</div>
							<br />
							<br />
							<br />
							<h4 className="bootstrap-overrides" id="operationHours">Our operation hours are Monday-Friday from 8:00 AM to 5:00 PM and from 8:00 AM to 12:00 PM on Saturdays</h4>
						</div>
						<div className="col-sm-1 col-lg-2"></div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}
export default About;
