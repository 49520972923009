import React from 'react';

class Footer extends React.Component {


		constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (

				<div id="this-footer"><h5>Yobo Computer Systems</h5><h5><a href="tel:+14152865577">(415) 286-5577</a></h5></div>

		       )
	}
}
export default Footer;
